<template>
	<v-dialog persistent max-width="764px" ref="updateDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title class="pl-4 pr-4">
					<span class="text-h5 mb-3">Update Permission</span>
				</v-card-title>
				<v-card-text class="pl-4 pr-4">
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense label="Permission Name*" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field dense label="Description*" :rules="rules.description" :error-messages="errors.description" v-model="object.description"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <v-checkbox dense label="Visible" :error-messages="errors.is_visible" v-model="object.is_visible"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="save">Save Changes</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

export default {
	name: 'PermissionUpdateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                id: '',
                name: '',
                description: '',
                is_visible: '',
			},
			rules: {
				name: [
					(value) => !!value || 'Name is required',
					(value) => value && value.length >= 2 && value.length <= 100 || 'Name must be between 2 and 100 characters'
				],
				description: [
					(value) => !!value || 'Description is required',
				]
			},
			errors: {}
		}
	},
	computed: {
		updateDialog() {
			return this.$refs.updateDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
			this.loading = false
			this.form.reset()
			this.form.resetValidation()
            this.object = { name: '', id: '', description: '', is_visible: '' }
            this.errors = {}
        },
        updateObject: function(item) {
            this.object = item
        },
		save: function() {
			if(this.form.validate()){
				this.loading = true
				const object = { name: this.object.name, description: this.object.description, is_visible: this.object.is_visible }
                this.$store.dispatch('epanel/permission/updatePermission', { id: this.object.id, object: object }).then((response) => {
                    this.$emit('updated', { object: response.data.object, message: response.message })
                    this.close()
                }).catch((errors) => {
                    this.errors = errors.data.errors
                    this.loading = false
                })
			}
		}
	}
}

</script>