<template>
	<div>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-sheet color="white" elevation="0" class="pa-0">
					<p class="text-h6 mb-4">Search</p>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
							<v-autocomplete dense label="Category Name" :items="categories" v-model="filters.category"></v-autocomplete>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9" order="last">
							<v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click='search'>
								<v-icon small left>mdi-magnify</v-icon> Search
							</v-btn>
							<v-btn small class="elevation-0" :ripple="false" @click='reset'>
								<v-icon small left>mdi-undo</v-icon> Reset
							</v-btn>
						</v-col>
					</v-row>
				</v-sheet>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
				<v-data-table calculate-widths hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
					<template v-slot:item='{ item, index }'>
						<tr>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.description"></td>
							<td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.is_visible.text"></td>
                            <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                <v-menu bottom offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn small icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="openUpdateDialog(index, item)">
                                            <v-list-item-title>Edit Permission</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </td>
						</tr>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import { copyObject } from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import PermissionUpdateDialog from './PermissionUpdateDialog.vue'

export default {
	name: 'PermissionCategoryList',
    components: {
        updateDialog: PermissionUpdateDialog,
        messageNotifier: MessageNotifier,
    },
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				category: '',
			},
			categories: [],
			items: [],
			headers: []
		}
	},
    computed: {
        updateDialog() {
            return this.$refs.updateDialog
        },
        notifier() {
            return this.$refs.notifier
        }
    },
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			const filters = { category: this.filters.category }
			this.$store.dispatch('epanel/permission/getPermissions', filters).then((response) => {
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.categories = response.data.categories
				this.filters.category = response.data.object.value
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.get()
		},
		reset: function() {
			this.filters = { category: ''}
		},
        openUpdateDialog: function(index, item) {
            this.selectedIndex = index
            var object = copyObject(item, ['is_visible', 'permission_quantity'])
            object.is_visible = item.is_visible.value
            this.updateDialog.updateObject(object)
            this.updateDialog.open()
        },
        openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItem: function(item){
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
        },
	}
}

</script>